import { createContext } from 'react'
import {
  ContentRestrictionType,
  NavigatorSortBy,
  QuestionsSortBy,
  CustomReleasesSortBy,
  ReleaseStage,
  ReleaseType,
  SidebarView,
  StoriesSortBy,
  TagMatchType,
  CommentsSortBy,
} from '@types'
import { Category } from './CategoriesContext'

export enum FiltersView {
  Tags = 'tags',
  Categories = 'categories',
}
export interface LocalFilters {
  commentsNewest: boolean
  sortComments: CommentsSortBy
  contentGroups: ContentRestrictionType[]
  contentGroupCategories: ContentRestrictionType[]
  questionsSortBy: QuestionsSortBy
  navigatorSortBy: NavigatorSortBy
  navigatorSecondarySortBy?: NavigatorSortBy
  releasesSortBy: CustomReleasesSortBy
  storiesSortBy: StoriesSortBy
  releaseType?: ReleaseType
  view?: FiltersView
  categories?: Category[]
  categoriesAlpha?: Category[]
  categoriesEarlyAccess?: Category[]
  releaseCategories?: Category[]
  releaseCategoriesAlpha?: Category[]
  releaseCategoriesEarlyAccess?: Category[]
  releaseCategoriesSecurityUpdates?: boolean
  tags?: string[]
  tagsAlpha?: string[]
  tagsBeta?: string[]
  tagsSecurityUpdates?: boolean
  tagMatchType?: TagMatchType
  releaseContentGroups: ContentRestrictionType[]
  releaseContentGroupCategories: ContentRestrictionType[]
  releaseTags?: string[]
  releaseTagsAlpha?: string[]
  releaseTagsBeta?: string[]
  releaseTagMatchType?: TagMatchType
  searchContentGroups?: ContentRestrictionType[]
  searchReleaseType?: ReleaseType
  searchTags?: string[]
  searchTagsAlpha?: string[]
  searchTagsBeta?: string[]
  searchTagMatchType?: TagMatchType
  sidebarView: SidebarView
}

export interface FiltersUpdate {
  commentsNewest?: boolean
  sortComments?: CommentsSortBy
  contentGroups?: ContentRestrictionType[]
  contentGroupCategories?: ContentRestrictionType[]
  questionsSortBy?: QuestionsSortBy
  navigatorSortBy?: NavigatorSortBy
  navigatorSecondarySortBy?: NavigatorSortBy
  releasesSortBy?: CustomReleasesSortBy
  storiesSortBy?: StoriesSortBy
  releaseStage?: ReleaseStage
  releaseType?: ReleaseType
  view?: FiltersView
  categories?: Category[]
  categoriesAlpha?: Category[]
  categoriesEarlyAccess?: Category[]
  releaseCategories?: Category[]
  releaseCategoriesAlpha?: Category[]
  releaseCategoriesEarlyAccess?: Category[]
  releaseCategoriesSecurityUpdates?: boolean
  tags?: string[]
  tagsAlpha?: string[]
  tagsBeta?: string[]
  tagsSecurityUpdates?: boolean
  tagMatchType?: TagMatchType
  releaseContentGroups?: ContentRestrictionType[]
  releaseContentGroupCategories?: ContentRestrictionType[]
  releaseTags?: string[]
  releaseTagsAlpha?: string[]
  releaseTagsBeta?: string[]
  releaseTagMatchType?: TagMatchType
  storyTagMatchType?: TagMatchType
  searchContentGroups?: ContentRestrictionType[]
  searchReleaseStage?: ReleaseStage
  searchReleaseType?: ReleaseType
  searchTags?: string[]
  searchTagsAlpha?: string[]
  searchTagsBeta?: string[]
  searchTagMatchType?: TagMatchType
  sidebarView?: SidebarView
}

export interface FilterContextShape {
  getValidTags: (tags: string[]) => string[]
  filters: LocalFilters
  updateFilters: (filters: FiltersUpdate) => void
  updateUserDetails: (tags: string[], theme: string) => void
}
export const FilterContext = createContext<FilterContextShape>({
  getValidTags: () => [],
  filters: {
    commentsNewest: false,
    sortComments: CommentsSortBy.Oldest,
    contentGroups: [ContentRestrictionType.Public],
    contentGroupCategories: [ContentRestrictionType.Public],
    releaseContentGroups: [ContentRestrictionType.Public],
    releaseContentGroupCategories: [ContentRestrictionType.Public],
    sidebarView: SidebarView.Tags,
    navigatorSortBy: NavigatorSortBy.Newest,
    questionsSortBy: QuestionsSortBy.Latest,
    releasesSortBy: CustomReleasesSortBy.Latest,
    storiesSortBy: StoriesSortBy.Latest,
    view: FiltersView.Categories,
  },

  updateFilters: () => {},

  updateUserDetails: () => {},
} as FilterContextShape)
