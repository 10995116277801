import { createContext } from 'react'

import { ScreenBreakpoints } from '@core/config'

export interface ScreenSizeShape {
  width: number
  height: number
  screenHeight: ScreenBreakpoints
  screenWidth: ScreenBreakpoints
}

const initialScreenSize = {
  width: window.innerWidth,
  height: window.innerHeight,
  screenHeight: ScreenBreakpoints.XS,
  screenWidth: ScreenBreakpoints.XS,
}

export const ScreenSize = createContext<ScreenSizeShape>(initialScreenSize)
