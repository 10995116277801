import { ErrorIcon } from './ErrorIcon'
import { InfoIcon } from './InfoIcon'
import { SuccessIcon } from './SuccessIcon'
import { Text } from '@ubnt/ui-components/Text'
import { WarningIcon } from './WarningIcon'
import styled from 'styled-components'
import { typography } from '@styles/theme'

export enum ToastType {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

const ICON_MAP = {
  [ToastType.Info]: <InfoIcon />,
  [ToastType.Warning]: <WarningIcon />,
  [ToastType.Error]: <ErrorIcon />,
  [ToastType.Success]: <SuccessIcon />,
}

export const ToastContent: React.FC<{
  title?: string
  type?: ToastType
  messages?: string[]
  actionLabel?: string
  onAction?: () => void
}> = ({ title, messages, type = ToastType.Info, actionLabel, onAction }) => {
  return (
    <Wrapper>
      <IconWrapper className={type}>{ICON_MAP[type]}</IconWrapper>
      <ContentWrapper>
        {!!title && (
          <StyledTitle size="caption" weight="bold">
            {title}
          </StyledTitle>
        )}
        {!!messages?.length && (
          <MessagesWrap>
            {messages.map((message, index) => (
              <StyledDescription size="caption" key={`${message}-${index}`}>
                {message}
              </StyledDescription>
            ))}
          </MessagesWrap>
        )}

        {!!actionLabel && (
          <StyledButton onClick={onAction}>{actionLabel}</StyledButton>
        )}
      </ContentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const MessagesWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.ublue06};
  font: ${typography['desktop-caption']};
  outline: none;
  width: fit-content;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.ublue05};
  }
`

const IconWrapper = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  align-self: baseline;

  svg {
    flex-shrink: 0;
  }

  &.success svg {
    color: ${({ theme }) => theme.green05};
  }

  &.info svg {
    color: ${({ theme }) => theme.ublue05};
  }

  &.warning svg {
    color: ${({ theme }) => theme.orange05};
  }

  &.error svg {
    color: ${({ theme }) => theme.red05};
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledTitle = styled(Text)`
  color: white;
  line-height: 20px;
`

export const StyledDescription = styled(Text)`
  color: #dee0e3;
  line-height: 20px;
`
