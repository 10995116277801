import theme from '@styles/theme'

export const SuccessIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="6" fill="white" />
      <path
        d="M10 2C5.582 2 2 5.582 2 10C2 14.418 5.582 18 10 18C14.418 18 18 14.418 18 10C18 5.582 14.418 2 10 2ZM6.28675 10.6914C5.99369 10.3985 5.99373 9.92349 6.28683 9.63067C6.57968 9.3381 7.05421 9.33821 7.34692 9.63092L8.938 11.222L12.6515 7.5085C12.9444 7.21555 13.4194 7.21567 13.7122 7.50875C14.0049 7.80164 14.0048 8.27625 13.712 8.569L9.64588 12.6351C9.25545 13.0256 8.62245 13.0257 8.23189 12.6353L6.28675 10.6914Z"
        fill={theme.light.green06}
      />
    </svg>
  )
}
