/**
 * Use the service below to get a human readable name for a color.
 * Sometimes with similar grays it might return identical names. If that happens consider do you really need both
 * similar shades of that grey?
 *
 * https://chir.ag/projects/name-that-color/#6195ED
 */
const color = {
  white: '#FFFFFF',
  black: '#000000',
  athensGray: '#F6F6F8',
  darkAthensGray: '#EDEDF0',
  codGray: '#121212',
  concrete: '#F2F2F2',
  blueRibbon: '#006FFF',
  dodgerBlue: '#3797ff',
  graySuit: '#BDBFCC',
  mischka: '#DBDCE1',
  mineShaft: '#2A2A2A',
  grayChateau: '#9B9EA1',
  frenchGray: '#B6B9C4',
  blackSqueeze: '#E1ECF4',
  milkPunch: '#FFF4D2',
  lucky: '#B3901E',
  fairPink: '#FFE9EB',
  monza: '#D0021B',
  iron: '#DADBDE',
  dustyGray: '#999999',

  // rgba colours
  darkGray: 'rgba(0, 0, 0, 0.85)',
  regularGray: 'rgba(0, 0, 0, 0.65)',
  lightGray: 'rgba(0, 0, 0, 0.45)',
  lighterGray: 'rgba(0, 0, 0, 0.03)',
  offWhite: 'rgba(255, 255, 255, 0.6)',
  whiteTransparent: 'rgba(255, 255, 255, 0.05)',
} as const

export default color
