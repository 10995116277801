import {
  AnswerFragment,
  BasicQuestionFragment,
  BasicReleaseFragment,
  BasicStoryFragment,
  CommentFragment,
  Maybe,
  PermissionType,
  Question,
  Release,
  Story,
} from './generated'
import {
  MutationUpdaterFunction,
  DefaultContext,
  ApolloCache,
  DataProxy,
} from '@apollo/client'

export enum UserGroup {
  Owner = 'owner',
  Admin = 'admin',
  Moderator = 'moderator',
  PLM = 'plm',
  PLMPlus = 'plm-plus',
  Support = 'support',
  ReleaseContributor = 'release-contributor',
}

export enum ReleaseIndexURLParams {
  SearchTerm = 'q',
  Tags = 'tags',
}

export type TagSimple = {
  id: string
  title: string
}

export enum NavigatorSortBy {
  Newest = 'NEWEST',
  Trending = 'TRENDING',
  LastActivity = 'LASTACTIVITY',
  Following = 'FOLLOWING',
  Pinned = 'PINNED',
  Score = 'SCORE',
  Answers = 'ANSWERS',
  Views = 'VIEWS',
}

export const NavigatorSortOptions = [
  {
    label: 'Last Activity',
    value: NavigatorSortBy.LastActivity,
  },
  {
    label: 'Trending',
    value: NavigatorSortBy.Trending,
  },
  {
    label: 'Newest',
    value: NavigatorSortBy.Newest,
  },
  {
    label: 'Following',
    value: NavigatorSortBy.Following,
  },
  {
    label: 'Pinned',
    value: NavigatorSortBy.Pinned,
  },
]

export enum CustomReleasesSortBy {
  Latest = 'LATEST',
  Activity = 'ACTIVITY',
  Following = 'FOLLOWING',
  Views = 'VIEWS',
}

interface ReleasesSortByOption {
  value: CustomReleasesSortBy
  label: string
}

export const releasesSortOptions: ReleasesSortByOption[] = [
  { value: CustomReleasesSortBy.Latest, label: 'Newest' },
  { value: CustomReleasesSortBy.Activity, label: 'Last Activity' },
  { value: CustomReleasesSortBy.Following, label: 'Following' },
]

export enum ContentRestrictionType {
  Public = 'public',
  Alpha = 'alpha',
  Beta = 'beta',
}

export enum FilterParent {
  Question = 'question',
  Release = 'release',
  Story = 'story',
}

export enum SidebarView {
  Tags = 'tags',
  Tables = 'tables',
}

export interface SidebarLink {
  isSolved?: boolean
  score: number | null
  title: string | React.ReactNode
  to: string
}

export type ParsedPermissions = { [key in PermissionType]: boolean }
export type NonEmptyArray<T> = [T, ...T[]]

export type ExtendedAnswerFragment = {
  parentEntity?: Maybe<
    { __typename?: 'Question' } & Pick<
      Question,
      'id' | 'title' | 'slug' | 'tags' | 'betas' | 'alphas' | 'acceptedAnswer'
    >
  >
} & AnswerFragment

export type ExtendedCommentFragment = {
  parentEntity?: Maybe<
    | ({ __typename?: 'Release' } & Pick<
        Release,
        'entityType' | 'id' | 'title' | 'slug' | 'tags' | 'betas' | 'alphas'
      >)
    | ({ __typename?: 'Story' } & Pick<
        Story,
        'entityType' | 'id' | 'title' | 'slug' | 'tags'
      > & { betas: Story['tags']; alphas: Story['tags'] })
  >
} & CommentFragment

export type QuestionItem = {
  __typename: 'Question'
} & BasicQuestionFragment
export type ResponseItem = { __typename: 'Answer' } & ExtendedAnswerFragment
export type StoryItem = { __typename: 'Story' } & BasicStoryFragment
export type CommentItem = { __typename: 'Comment' } & ExtendedCommentFragment
export type ReleaseItem = { __typename: 'Release' } & BasicReleaseFragment

export type TMutationUpdaterFn<T, S> = MutationUpdaterFunction<
  T,
  S,
  DefaultContext,
  ApolloCache<DataProxy>
>
