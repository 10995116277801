const patternV4 = new RegExp(
  /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
)
export const isUUID = (s: string): boolean => patternV4.test(s)

// tests against basic 8-4-4-4-12 pattern, no version specifics
// because some very old users have non standard uuid (in SSO)
const patternUserID = new RegExp(
  /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i
)
export const isUserID = (s: string): boolean => patternUserID.test(s)
