import { createContext } from 'react'

export interface DropdownContextShape {
  id: string
  visible: boolean
  open: (id: string) => void
  close: () => void
  show: () => void
  hide: () => void
}

const initalDropdownContext = {
  id: '',
  visible: false,

  open: (_id: string) => {},

  close: () => {},

  show: () => {},

  hide: () => {},
}

export const DropdownContext = createContext<DropdownContextShape>(
  initalDropdownContext
)
