import * as Sentry from '@sentry/browser'

export const initalizeSentry = (): void => {
  const isIgnoredError = (event: Sentry.Event) => {
    const { exception } = event
    if (!exception || !exception.values) return false

    for (const val of exception.values) {
      if (
        !!val.value &&
        (val.value.indexOf(
          `Non-Error promise rejection captured with keys: isTrusted`
        ) !== -1 ||
          val.value.indexOf(
            `Non-Error promise rejection captured with keys: currentTarget`
          ) !== -1 ||
          val.value.indexOf(`CustomEvent`) !== -1 ||
          val.value.indexOf(`ucweb`) !== -1 ||
          val.value.indexOf(`should_do_lastpass_here`) !== -1 ||
          /**
           * Issue with 3rd-party chrome extensions causing 100'000s of errors to be sent
           */
          val.value.indexOf(`Extension context invalidated`) !== -1 ||
          /**
           * Ignore errors caused by a new release removing old chunks and they fail to load and requires a page refresh
           * @example
           * Loading chunk 27 failed.
           * Loading CSS chunk 4 failed.
           */
          val.value.indexOf(`Loading chunk`) !== -1 ||
          val.value.indexOf(`Loading CSS chunk`) !== -1)
      ) {
        return true
      }
    }

    return false
  }

  Sentry.init({
    beforeSend: (event) => {
      if (isIgnoredError(event)) {
        return null
      }
      return event
    },
    dsn: AppConfig.sentry.dsn,
    environment: AppConfig.sentry.environment,
    enabled: !!AppConfig.sentry.enabled,
    release: AppConfig.sentry.release,
    dist: AppConfig.sentry.dist,

    integrations: [
      /**
       * Send web-vital statics
       */
      Sentry.browserTracingIntegration(),
    ],
    /**
     * As of recommendation of sentry start off performance tracking slowly by only sampling 20% of the users
     * @source https://docs.sentry.io/platforms/javascript/guides/react/performance/#configure-the-sample-rate
     */
    tracesSampleRate: 0.2,
  })
}
