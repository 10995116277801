import { Location } from 'react-router-dom'
import { COMMUNITY_FEEDBACK_TAG, SLUG_PLACEHOLDER } from '@core/config'
import { EntityType, QuestionFragment } from '@types'
import { isUUID } from './uuid'
import { TRAINER_TAG_ID } from '@core/contexts'

export interface QueryStringParams {
  interval?: string
  page?: number
  searchTerm?: string
  tag?: string
  tags?: string[]
  betas?: string[]
  alphas?: string[]
  user?: string
  view?: string
  subview?: string
  notification?: string
  id?: string
  origin?: string
  questionsFilter?: string
}

export const locationForUser = (user: { id: string; slug: string }): string => {
  const slug = user.slug || SLUG_PLACEHOLDER
  return `/user/${slug}/${user.id}`
}

export const locationForQuestion = (question: {
  id: string
  slug: string
  alphas?: string[]
  isTrainerPost?: boolean
}): string => {
  const slug = question.slug || SLUG_PLACEHOLDER
  const isTrainer =
    question.isTrainerPost || question.alphas?.includes(TRAINER_TAG_ID)
  const path = isTrainer ? 'trainers' : 'questions'
  return `/${path}/${slug}/${question.id}`
}

export const locationForStory = (
  story: {
    id: string
    slug: string
  },
  isContest?: boolean
): string => {
  const slug = story.slug || SLUG_PLACEHOLDER
  return `/stories${isContest ? '/contest' : ''}/${slug}/${story.id}`
}

export const locationForRelease = (release: {
  id: string
  slug: string
  tags?: string[]
}): string => {
  const slug = release.slug || SLUG_PLACEHOLDER
  const isFeedback =
    release.tags && release.tags[0] === COMMUNITY_FEEDBACK_TAG.id
  return `/${isFeedback ? 'community-feedback/' : ''}releases/${slug}/${
    release.id
  }`
}

export const locationForQuestionOrigin = (
  originEntity: NonNullable<QuestionFragment['originEntity']>
): string => {
  if (!originEntity.parentEntity) {
    return ''
  }

  switch (originEntity.parentEntity.entityType) {
    case EntityType.Question:
      return (
        locationForQuestion(originEntity.parentEntity) +
        '#answer/' +
        originEntity.id
      )
    case EntityType.Release:
      return (
        locationForRelease(originEntity.parentEntity) +
        '#comment/' +
        originEntity.id
      )
    case EntityType.Story:
      return (
        locationForStory(originEntity.parentEntity) +
        '#comment/' +
        originEntity.id
      )
    default:
      return ''
  }
}

export const locationForName = (
  location: Location,
  name: string,
  value?: string
) => {
  const { hash, pathname, search } = location

  const params = new URLSearchParams(search)
  if (!value) {
    params.delete(name)
  } else {
    params.set(name, value)
  }

  return {
    hash,
    pathname,
    search: params.toString(),
  }
}

export const locationForPage = (location: Location, page: number) =>
  locationForName(location, 'page', page.toString())

export const locationForSearch = (location: Location, search: string) =>
  locationForFirstPage(locationForName(location, 'q', search) as Location)

export const locationForQuestionFilter = (location: Location, value?: string) =>
  locationForFirstPage(locationForName(location, 'q_filter', value) as Location)

export const locationForPageNoScrollToTop = (
  location: Location,
  page: number
) => locationForNameNoScrollToTop(location, 'page', page.toString())

const locationForNameNoScrollToTop = (
  location: Location,
  name: string,
  value?: string
) => {
  const { hash, pathname, search } = location

  const params = new URLSearchParams(search)
  if (!value) {
    params.delete(name)
  } else {
    params.set(name, value)
  }

  return {
    hash,
    pathname,
    search: params.toString(),
    state: { noScrollToTop: true },
  }
}

export const locationForView = (
  location: Location,
  view: string,
  clearPage?: boolean
) => {
  const name = 'view'
  if (clearPage) {
    const { hash, pathname, search } = location

    const params = new URLSearchParams(search)
    params.delete('page')
    params.delete('subview')
    if (!view) {
      params.delete(name)
    } else {
      params.set(name, view)
    }

    return {
      hash,
      pathname,
      search: params.toString(),
    }
  }
  return locationForName(location, name, view)
}

export const locationForSubview = (
  location: Location,
  view: string,
  clearPage?: boolean
) => {
  const name = 'subview'
  if (clearPage) {
    const { hash, pathname, search } = location

    const params = new URLSearchParams(search)
    params.delete('page')
    if (!view) {
      params.delete(name)
    } else {
      params.set(name, view)
    }

    return {
      hash,
      pathname,
      search: params.toString(),
    }
  }
  return locationForName(location, name, view)
}

export const locationForFirstPage = (location: Location) => {
  const { hash, pathname, search } = location

  const params = new URLSearchParams(search)
  params.delete('page')

  return {
    hash,
    pathname,
    search: params.toString(),
  }
}

export const parseOffsetFromQueryString = (
  location: Location,
  itemsPerPage: number
): number => {
  const { page } = parseQueryString(location)

  if (!page) {
    return 0
  }

  return itemsPerPage * (page - 1)
}

export const parseQueryString = (location: Location): QueryStringParams => {
  const params = new URLSearchParams(location.search)

  const user = params.get('user') || undefined

  const pageString = params.get('page')
  const page = pageString ? Math.max(parseInt(pageString), 0) : undefined

  const searchTerm = params.get('q') || undefined

  const tag = params.get('tag') || undefined

  const tags = params.get('tags')?.split(',')
  const betas = params.get('betas')?.split(',')
  const alphas = params.get('alphas')?.split(',')

  const view = params.get('view') || undefined
  const subview = params.get('subview') || undefined

  const interval = params.get('interval') || undefined

  const notification = params.get('notification') || undefined

  const id = params.get('id') || undefined
  const origin = params.get('origin') || undefined

  const questionsFilter = params.get('q_filter') || undefined

  return {
    interval,
    page,
    searchTerm,
    tag,
    tags,
    betas,
    alphas,
    user,
    view,
    subview,
    notification,
    id,
    origin,
    questionsFilter,
  }
}

export const parseHashString = (
  location: Location
): {
  answer?: string
  comment?: string
} => {
  const arr = location.hash.substring(1).split('/')

  const arrayLength = arr.length
  let comment: string | undefined
  let answer: string | undefined
  for (let i = 0; i < arrayLength; i++) {
    switch (arr[i]) {
      case 'comment':
        comment = arr[i + 1]
        i++
        break
      case 'answer':
        answer = arr[i + 1]
        i++
        break
    }
  }

  if (comment && !isUUID(comment)) {
    comment = undefined
  }
  if (answer && !isUUID(answer)) {
    answer = undefined
  }

  return {
    answer,
    comment,
  }
}

export interface ReleaseQueryStringParams {
  searchTerm?: string
}

export const getRootPath = (pathname: string | null): string => {
  if (!pathname) {
    return ''
  }

  return pathname.split('/')[1]
}

export const isAppview = (location: typeof window.location): boolean => {
  const appviewQueryParam = location.search.includes('appview')
  const appviewCookie = document.cookie.includes('appview')
  return appviewCookie || appviewQueryParam
}
