import { media } from '@styles/theme'
import { Alert } from '@ubnt/ui-components/Alert'
import styled from 'styled-components'

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`

export const Element = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.neutral00};
  border-radius: 8px;
  box-shadow:
    0px 0px 1px 0px #21232714,
    0px 12px 48px 0px #2123271f;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 440px;
  min-height: 361px;
  padding: 32px;
  width: 100%;

  @media (max-width: ${media.small}) {
    max-width: calc(100vw - 32px);
    min-height: unset;
  }
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.text1};
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
`

export const Description = styled.p`
  color: ${({ theme }) => theme.text2};
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  width: 100%;
`

export const StyledImg = styled.img`
  max-width: 268px;
  max-height: 149px;
  width: 100%;
  height: auto;
`

export const Button = styled.button`
  align-items: center;
  border-radius: 4px;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 32px;
  justify-content: center;
  line-height: 20px;
  max-width: 268px;
  width: 100%;
  background: #006fff;
`

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

export const StyledAlert = styled(Alert)`
  &.error-alert > div {
    width: 100%;
  }
`

export const CollapsedContentWrap = styled.div<{ $padding?: string }>`
  padding: ${({ $padding }) => $padding ?? '0'};
  background: ${({ theme }) => theme.neutral00};
`

export const StyledCollapsedContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  p {
    text-align: left;
    width: auto;
  }
`
