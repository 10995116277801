import { TagType } from '@types'

export enum ScreenBreakpoints {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export const SCREEN_BREAKPOINT_VAL = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1046,
} as const

export const REPORT_COUNT_THRESHOLD = 5

export const MESSAGES_IN_POLLING_INTERVAL = 25
export const MESSAGES_OUT_POLLING_INTERVAL = 60
export const MESSAGES_COUNT_POLLING_INTERVAL = 60

export const NOTIFICATIONS_COUNT_POLLING_INTERVAL = 55

export const RELATED_LINK_COUNT = 5

export const MAX_EDIT_MINUTES = 90 - 5

export const GRID_ITEM_HEIGHT = 300

export const COVER_IMAGE_HEIGHT = 300

export const MAP_BUTTON_HEIGHT = 170
export const MAP_BUTTON_WIDTH = 250
export const MAP_ZOOM = 12

export const APP_TITLE = AppConfig.appTitle

export const COMMUNITY_FEEDBACK_TAG = {
  id: 'community-feedback',
  title: 'Community Feedback',
  type: TagType.Legacy,
}

export const SECURITY_TAG = {
  id: 'security',
  title: 'Security',
  type: TagType.Legacy,
}

export enum Theme {
  Light = 'light',
  Dark = 'dark',
}

export const FRONTEND_VERSION_HEADER = 'X-Frontend-Version'

export const SLUG_PLACEHOLDER = '___'

export const GAMIFICATION_LINK =
  '/questions/Introducing-Ubiquiti-Community-Reward-Program/3a1afa95-3261-4302-93e6-193568aaff9a'
