import { Theme } from '@core/config'
import theme from '@styles/theme'
import useMotif from '@ubnt/ui-components/utils/useMotif'

export const InfoIcon: React.FC = () => {
  const isDarkMode = useMotif().motif === Theme.Dark

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="6" fill="white" />
      <path
        d="M10 2C5.582 2 2 5.582 2 10C2 14.418 5.582 18 10 18C14.418 18 18 14.418 18 10C18 5.582 14.418 2 10 2ZM10.75 13.5C10.75 13.914 10.414 14.25 10 14.25C9.586 14.25 9.25 13.914 9.25 13.5V9.4C9.25 8.986 9.586 8.65 10 8.65C10.414 8.65 10.75 8.986 10.75 9.4V13.5ZM10 7.75C9.448 7.75 9 7.302 9 6.75C9 6.198 9.448 5.75 10 5.75C10.552 5.75 11 6.198 11 6.75C11 7.302 10.552 7.75 10 7.75Z"
        fill={isDarkMode ? theme.light.ublue05 : theme.light.ublue06}
      />
    </svg>
  )
}
