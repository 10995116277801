import * as S from './ErrorBox.styles'
import updateAvailable from '@images/updateAvailable.png'
import updateAvailable2x from '@images/updateAvailable@2x.png'
import { Button } from '@ubnt/ui-components/Button'
import { useMemo } from 'react'

interface IErrorBoxProps {
  title: string
  description?: string
  collapsed?: boolean
  padding?: string
  actionLabel?: string
  onReload: () => void
}

const APOLLO_ERROR_PREFIX = 'ApolloError:'

export const ErrorBox: React.FC<IErrorBoxProps> = ({
  title,
  description,
  padding,
  collapsed,
  actionLabel,
  onReload,
}) => {
  const formattedTitle = useMemo(() => {
    if (title?.includes(APOLLO_ERROR_PREFIX)) {
      const formatted = title.replace(APOLLO_ERROR_PREFIX, '')

      return formatted
    }

    return title ?? 'Unknown Error'
  }, [title])

  if (collapsed) {
    return (
      <S.CollapsedContentWrap $padding={padding}>
        <S.StyledAlert
          variant="inline"
          type="danger"
          className="error-alert"
          text={
            <S.StyledCollapsedContent>
              <S.Description>{formattedTitle}</S.Description>
              <Button variant="link" onClick={() => onReload()}>
                {actionLabel ?? 'Reload'}
              </Button>
            </S.StyledCollapsedContent>
          }
        />
      </S.CollapsedContentWrap>
    )
  }

  return (
    <S.Wrapper>
      <S.Element>
        <S.ContentWrap>
          <S.Title>{formattedTitle}</S.Title>
          {description && <S.Description>{description}</S.Description>}
        </S.ContentWrap>
        <S.StyledImg
          src={updateAvailable}
          srcSet={`${updateAvailable2x} 2x`}
          alt="Unknown error"
        />
        <S.Button type="button" onClick={() => onReload()}>
          {actionLabel ?? 'Reload'}
        </S.Button>
      </S.Element>
    </S.Wrapper>
  )
}
