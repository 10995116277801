import * as React from 'react'

export interface LazyWrapperProps {
  placeholder?: React.ReactNode
}

export const LazyWrapper: React.FC<LazyWrapperProps> = ({
  placeholder = null,
  children,
}) => (
  <ErrorBoundary>
    <React.Suspense fallback={placeholder}>{children}</React.Suspense>
  </ErrorBoundary>
)

interface StateData {
  hasError: boolean
}

type Props = Record<string, unknown>
type State = Readonly<StateData>

const isCrawlerRegex =
  /bot|googlebot|google-structured-data-testing-tool|bingbot|linkedinbot|mediapartners-google|crawler|spider|robot|crawling/i

class ErrorBoundary extends React.Component<Props, State> {
  readonly state: State = { hasError: false }

  static getDerivedStateFromError(_error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(_error: unknown, _errorInfo: unknown) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const CDN_URL = AppConfig.publicAssetsUrl

    if (this.state.hasError && !isCrawlerRegex.test(navigator.userAgent)) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              background: 'white',
              borderRadius: '8px',
              boxShadow: `0px 0px 1px 0px #21232714,
                0px 12px 48px 0px #2123271f`,
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              maxWidth: '440px',
              minHeight: '361px',
              padding: '32px',
              width: '100%',
            }}
          >
            <h2
              style={{
                color: '#212327',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '28px',
              }}
            >
              Update Available
            </h2>
            <p
              style={{
                color: '#50565e',
                fontSize: '14px',
                lineHeight: '20px',
                textAlign: 'center',
              }}
            >
              A new update is now available in the Community. Refresh to learn
              about the latest features and improvements.
            </p>
            <img
              style={{
                maxWidth: '268px',
                maxHeight: '149px',
                width: '100%',
                height: 'auto',
              }}
              src={`${CDN_URL}/updateAvailable.png`}
              srcSet={`${CDN_URL}/updateAvailable@2x.png 2x`}
              alt="update available"
            />
            <button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                background: '#006FFF',
                color: 'white',
                borderRadius: '4px',
                padding: '0 16px',
                height: '32px',
                fontSize: '14px',
                lineHeight: '20px',
                maxWidth: '268px',
              }}
              className="btn-primary"
              onClick={() => window.location.reload()}
            >
              Reload
            </button>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
