import { createContext, useState } from 'react'

export interface NavigationMenuContextShape {
  searchTerm: string
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>
}

export const NavigationMenuContext = createContext<NavigationMenuContextShape>({
  searchTerm: '',
  setSearchTerm: undefined,
})

export const NavigationMenuContextProvider: React.FC = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState('')

  return (
    <NavigationMenuContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
      }}
    >
      {children}
    </NavigationMenuContext.Provider>
  )
}
