import color from '@styles/color'

export type LegacyTheme = {
  isDarkTheme: boolean

  pageBackground: string
  headerBackground: string
  mainBlockBackground: string
  secondaryBlockBackground: string
  highlightSecondaryBlock: string
  mobileInputBackgroundColor: string
  backgroundColor: string

  mainTitle: string
  secondaryTitle: string
  mainText: string
  secondaryText: string
  lightText: string
  highlightTextColor: string
  placeholderTextColor: string

  divider: string
  headerBorder: string
  tableBorder: string
  navBorder: string
  homeGradient: string
  smallHomeGradient: string
}

export const legacyColorsLight: LegacyTheme = {
  isDarkTheme: false,

  backgroundColor: color.white,
  pageBackground: color.concrete,
  headerBackground: color.athensGray,
  mainBlockBackground: color.white,
  secondaryBlockBackground: color.offWhite,
  highlightSecondaryBlock: color.white,
  mobileInputBackgroundColor: color.concrete,

  mainTitle: color.darkGray,
  secondaryTitle: color.darkGray,
  mainText: color.regularGray,
  secondaryText: color.lightGray,
  lightText: color.lighterGray,
  highlightTextColor: color.blueRibbon,
  placeholderTextColor: color.frenchGray,

  divider: color.mischka,
  headerBorder: color.darkAthensGray,
  tableBorder: color.athensGray,
  navBorder: 'inset 0px -1px 0px #EEEEEE',
  homeGradient:
    'linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 71.82%)',
  smallHomeGradient:
    'linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ffffff 50%)',
}

export const legacyColorsDark: LegacyTheme = {
  isDarkTheme: true,

  backgroundColor: color.black,
  pageBackground: color.black,
  headerBackground: color.codGray,
  mainBlockBackground: color.codGray,
  secondaryBlockBackground: color.codGray,
  highlightSecondaryBlock: color.mineShaft,
  mobileInputBackgroundColor: color.mineShaft,

  mainTitle: color.white,
  secondaryTitle: color.white,
  mainText: color.grayChateau,
  secondaryText: color.grayChateau,
  lightText: color.offWhite,
  highlightTextColor: color.white,
  placeholderTextColor: color.frenchGray,

  divider: color.mineShaft,
  headerBorder: color.black,
  tableBorder: color.mineShaft,
  navBorder: 'inset 0px 1px 0px #323339, inset 0px -1px 0px #323339',
  homeGradient:
    'linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #121212 71.82%)',
  smallHomeGradient:
    'linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #121212 50%)',
}
