import theme from '@styles/theme'

export const WarningIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4998 17.5H2.50016C1.7318 17.5 1.25119 16.667 1.63488 16L5.38479 9.5L9.1347 3C9.32654 2.667 9.66327 2.5 9.99999 2.5C10.3367 2.5 10.6734 2.667 10.8653 3L14.6152 9.5L18.3651 16C18.7488 16.667 18.2682 17.5 17.4998 17.5Z"
        fill="white"
      />
      <path
        d="M18.3656 16.0002L14.6157 9.50025L10.8658 3.00025C10.4811 2.33325 9.51993 2.33325 9.13525 3.00025L5.38534 9.50025L1.63543 16.0002C1.25074 16.6672 1.73135 17.5002 2.50071 17.5002H10.0005H17.5004C18.2687 17.5002 18.7503 16.6672 18.3656 16.0002ZM9.25116 7.75025C9.25116 7.33625 9.58688 7.00025 10.0005 7.00025C10.4142 7.00025 10.7499 7.33625 10.7499 7.75025V11.8502C10.7499 12.2642 10.4142 12.6002 10.0005 12.6002C9.58688 12.6002 9.25116 12.2642 9.25116 11.8502V7.75025ZM10.0005 15.5002C9.44899 15.5002 9.00136 15.0522 9.00136 14.5002C9.00136 13.9482 9.44899 13.5002 10.0005 13.5002C10.5521 13.5002 10.9997 13.9482 10.9997 14.5002C10.9997 15.0522 10.5521 15.5002 10.0005 15.5002Z"
        fill={theme.light.orange06}
      />
    </svg>
  )
}
