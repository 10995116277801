import {
  GetRestrictionsQuery,
  GetTagsQuery,
  Restriction,
  RestrictionType,
  Tag,
  TagType,
} from '@types'
import { COMMUNITY_FEEDBACK_TAG, SECURITY_TAG } from '@core/config'
import { TRAINER_TAG_ID } from '@core/contexts'

export const mapUserTags = (
  { tags }: GetTagsQuery,
  { restrictions = [] }: GetRestrictionsQuery
) => {
  const PUBLIC_TAGS = tags.filter((tag) => tag.type === TagType.Public)
  const PUBLIC_TAG_IDS = PUBLIC_TAGS.map((tag) => tag.id)

  const PUBLIC_TAGS_GROUPED: Tag[][] = []
  for (let i = 0, l = PUBLIC_TAGS.length; i < l; i++) {
    const tag = PUBLIC_TAGS[i]
    if (!PUBLIC_TAGS_GROUPED[tag.group || 0]) {
      PUBLIC_TAGS_GROUPED[tag.group || 0] = []
    }
    PUBLIC_TAGS_GROUPED[tag.group || 0].push(tag)
  }

  const PUBLIC_TAGS_BY_CATEGORY: Record<string, Tag[]> = {}

  PUBLIC_TAGS.forEach((tag) => {
    const category = tag.category?.title

    if (!category) return

    if (!PUBLIC_TAGS_BY_CATEGORY[category]) {
      PUBLIC_TAGS_BY_CATEGORY[category] = []
    }
    PUBLIC_TAGS_BY_CATEGORY[category].push(tag)
  })

  let BETA_TAGS: Restriction[] | undefined
  let BETA_TAG_IDS: string[] | undefined
  let BETA_TAGS_GROUPED: Restriction[][] | undefined
  let BETA_TAGS_BY_CATEGORY: Record<string, Restriction[]> | undefined

  let ALPHA_TAGS: Restriction[] | undefined
  let ALPHA_TAG_IDS: string[] | undefined
  let ALPHA_TAGS_GROUPED: Restriction[][] | undefined
  let ALPHA_TAGS_BY_CATEGORY: Record<string, Restriction[]> | undefined

  let TRAINERS_TAGS: Restriction[] | undefined

  if (restrictions?.length > 0) {
    BETA_TAGS = restrictions.filter(
      (tag) => tag.type === RestrictionType.Beta && tag.id !== 'beta:security'
    )

    if (BETA_TAGS.length === 0) {
      BETA_TAGS = undefined
    } else {
      BETA_TAG_IDS = BETA_TAGS.map((tag) => tag.id)
      BETA_TAGS_GROUPED = []
      const BETA_TAGS_COUNT = BETA_TAGS.length
      for (let i = 0; i < BETA_TAGS_COUNT; i++) {
        const tag = BETA_TAGS[i]
        if (!BETA_TAGS_GROUPED[tag.group || 0]) {
          BETA_TAGS_GROUPED[tag.group || 0] = []
        }
        BETA_TAGS_GROUPED[tag.group || 0].push(tag)
      }
      BETA_TAGS_BY_CATEGORY = {}
      BETA_TAGS.forEach((tag) => {
        const category = tag.category?.title

        if (!category || !BETA_TAGS_BY_CATEGORY) return

        if (!BETA_TAGS_BY_CATEGORY[category]) {
          BETA_TAGS_BY_CATEGORY[category] = []
        }
        BETA_TAGS_BY_CATEGORY[category].push(tag)
      })
    }

    ALPHA_TAGS = restrictions?.filter(
      (tag) => tag.type === RestrictionType.Alpha && tag.id !== TRAINER_TAG_ID
    )

    TRAINERS_TAGS = restrictions?.filter(
      (tag) => tag.type === RestrictionType.Alpha && tag.id === TRAINER_TAG_ID
    )

    if (ALPHA_TAGS.length === 0) {
      ALPHA_TAGS = undefined
    } else {
      ALPHA_TAG_IDS = ALPHA_TAGS.map((tag) => tag.id)
      ALPHA_TAGS_GROUPED = []
      const ALPHA_TAGS_COUNT = ALPHA_TAGS.length
      for (let i = 0; i < ALPHA_TAGS_COUNT; i++) {
        const tag = ALPHA_TAGS[i]
        if (!ALPHA_TAGS_GROUPED[tag.group || 0]) {
          ALPHA_TAGS_GROUPED[tag.group || 0] = []
        }
        ALPHA_TAGS_GROUPED[tag.group || 0].push(tag)
      }
      ALPHA_TAGS_BY_CATEGORY = {}
      ALPHA_TAGS.forEach((tag) => {
        const category = tag.category?.title

        if (!category || !ALPHA_TAGS_BY_CATEGORY) return

        if (!ALPHA_TAGS_BY_CATEGORY[category]) {
          ALPHA_TAGS_BY_CATEGORY[category] = []
        }
        ALPHA_TAGS_BY_CATEGORY[category].push(tag)
      })
    }
  }

  return {
    ALL_TAGS: [...tags, ...(restrictions || [])],
    TAGS: [...tags, COMMUNITY_FEEDBACK_TAG, SECURITY_TAG],
    RESTRICTIONS: restrictions ?? [],
    PUBLIC_TAGS,
    PUBLIC_TAG_IDS,
    PUBLIC_TAGS_GROUPED,
    PUBLIC_TAGS_BY_CATEGORY,
    BETA_TAGS,
    BETA_TAG_IDS,
    BETA_TAGS_GROUPED,
    BETA_TAGS_BY_CATEGORY,
    ALPHA_TAGS,
    ALPHA_TAG_IDS,
    ALPHA_TAGS_GROUPED,
    ALPHA_TAGS_BY_CATEGORY,
    TRAINERS_TAGS,
  }
}
