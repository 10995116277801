import { useQuery } from '@apollo/client'
import { ErrorBox } from '@components/Error'
import { createContext, useMemo } from 'react'
import { mapUserTags } from '@components/Tag/utils'
import {
  GetRestrictionsDocument,
  GetTagsDocument,
  Restriction,
  Tag,
} from '@types'

export interface TagContextShape {
  ALL_TAGS: (Tag | Restriction)[]
  TAGS: Tag[]
  RESTRICTIONS?: Restriction[]
  PUBLIC_TAGS: Tag[]
  PUBLIC_TAG_IDS: string[]
  PUBLIC_TAGS_GROUPED: Tag[][]
  PUBLIC_TAGS_BY_CATEGORY: Record<string, Tag[]>
  BETA_TAGS?: Restriction[]
  BETA_TAG_IDS?: string[]
  BETA_TAGS_GROUPED?: Restriction[][]
  BETA_TAGS_BY_CATEGORY?: Record<string, Restriction[]>
  ALPHA_TAGS?: Restriction[]
  ALPHA_TAG_IDS?: string[]
  ALPHA_TAGS_GROUPED?: Restriction[][]
  ALPHA_TAGS_BY_CATEGORY?: Record<string, Restriction[]>
  TRAINERS_TAGS?: Restriction[]
  hasLoadedTags: boolean
}

const BASE_STATE = {
  ALL_TAGS: [],
  TAGS: [],
  PUBLIC_TAGS: [],
  PUBLIC_TAG_IDS: [],
  PUBLIC_TAGS_GROUPED: [],
  PUBLIC_TAGS_BY_CATEGORY: {},
  BETA_TAGS: undefined,
  BETA_TAG_IDS: undefined,
  BETA_TAGS_GROUPED: undefined,
  BETA_TAGS_BY_CATEGORY: undefined,
  ALPHA_TAGS: undefined,
  ALPHA_TAG_IDS: undefined,
  ALPHA_TAGS_GROUPED: undefined,
  ALPHA_TAGS_BY_CATEGORY: undefined,
  TRAINERS_TAGS: undefined,
  hasLoadedTags: false,
}

export const TagContext = createContext<TagContextShape>({
  ...BASE_STATE,
})

export const TRAINER_TAG_ID = 'alpha:trainers'
export const GENERAL_TAG_ID = 'general'
export const NEW_TO_UNIFI_TAG_ID = 'new-to-unifi'

export const TagContextProvider: React.FC<{
  isAuthenticated: boolean
}> = ({ children, isAuthenticated }) => {
  const { data, error, loading, refetch } = useQuery(GetTagsDocument, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })
  const { data: restrictions, loading: isLoadingRestrictions } = useQuery(
    GetRestrictionsDocument
  )

  const tagState = useMemo(() => {
    if (loading || !data?.tags?.length) {
      return BASE_STATE
    }

    return mapUserTags(data, restrictions ?? { restrictions: [] })
  }, [data, restrictions, loading])

  const hasLoadedTags = useMemo(() => {
    if (loading || (isAuthenticated && isLoadingRestrictions)) {
      return false
    }

    return !!tagState.ALL_TAGS.length
  }, [loading, isLoadingRestrictions, isAuthenticated, tagState.ALL_TAGS])

  if (error) {
    return <ErrorBox error={error} refetch={refetch} />
  }

  return (
    <TagContext.Provider
      value={{
        ...tagState,
        hasLoadedTags,
      }}
    >
      {children}
    </TagContext.Provider>
  )
}
