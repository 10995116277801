import { Theme } from '@core/config'
import theme from '@styles/theme'
import useMotif from '@ubnt/ui-components/utils/useMotif'

export const ErrorIcon: React.FC = () => {
  const isDarkMode = useMotif().motif === Theme.Dark

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="6" fill="white" />
      <path
        d="M10 2C5.582 2 2 5.582 2 10C2 14.418 5.582 18 10 18C14.418 18 18 14.418 18 10C18 5.582 14.418 2 10 2ZM12.8285 11.7675C13.1215 12.0605 13.1215 12.5355 12.8285 12.8285C12.5355 13.1215 12.0605 13.1215 11.7675 12.8285L10 11.061L8.2325 12.8285C7.93951 13.1215 7.46449 13.1215 7.1715 12.8285C6.87851 12.5355 6.87851 12.0605 7.1715 11.7675L8.939 10L7.1715 8.2325C6.87851 7.93951 6.87851 7.46449 7.1715 7.1715C7.46449 6.87851 7.93951 6.87851 8.2325 7.1715L10 8.939L11.7675 7.1715C12.0605 6.87851 12.5355 6.87851 12.8285 7.1715C13.1215 7.46449 13.1215 7.93951 12.8285 8.2325L11.061 10L12.8285 11.7675Z"
        fill={isDarkMode ? theme.light.red05 : theme.light.red06}
      />
    </svg>
  )
}
