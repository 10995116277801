import { ErrorPage_Default } from '@ubnt/ui-components/aria'
import styled from 'styled-components'
import { media, typography } from './theme'

export const StyledErrorPage = styled(ErrorPage_Default)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 560px;
  padding: 40px 24px;
  width: 100%;

  h1,
  div {
    width: 100%;
    margin: 0 !important;
  }

  p {
    max-width: 100% !important;
  }

  @media (max-width: ${media.small}) {
    min-height: 480px;

    h1 {
      font: ${typography['desktop-heading-xlarge-1']} !important;
    }
    svg {
      width: 128px;
      height: 128px;
    }
  }
`
